import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import {
    Route,
    Routes,
    useLocation,
    Location,
    Navigate,
} from 'react-router-dom'
import { AuthToken } from '../../api/token_api'
import { UserApi } from '../../api/users_api'
import { User } from '../../interfaces/user_interface'
import { AlgosChangelogs } from '../../routes/algos_changelog/algos_changelog_route'
import { AlgosJS } from '../../routes/algos_js/algos_js_route'
import { Apk } from '../../routes/apk/apk_route'
// import { Login } from '../../routes/authentification/login_route'
import NeedPasswordChange from '../../routes/authentification/need_password_change_route'
import { CGUs } from '../../routes/cgu/cgu_route'
import { Credits } from '../../routes/credits/credits_route'
import { Funders } from '../../routes/funders/funders_route'
import {
    EditionGeorestriction,
    EditionGeorestrictionMiddleWare,
} from '../../routes/georestrictions/edition_georestriction'
import { Georestrictions } from '../../routes/georestrictions/georestrictions_route'
import Home from '../../routes/home/home_route'
import { Rasters } from '../../routes/rasters/rasters_route'
import { Structures } from '../../routes/structures/structures_route'
import {
    EditionUser,
    EditionUserMiddleWare,
} from '../../routes/users/edition_user'
import { Users } from '../../routes/users/users_route'
import { SideNav } from '../Navigation/SideNav/SideNav'
import { Export } from '../../routes/export/export_route'

// TODO : renommer ce widget et lui donner un nom plus explicite : RouterSignedIn

export function DashboardMiddleWare(): React.ReactElement {
    return <Dashboard location={useLocation()} />
}

interface DashboardProps {
    location: Location
}
interface DashboardState {
    user?: User
    error?: boolean
    token?: string | null
}

export class Dashboard extends React.Component<DashboardProps, DashboardState> {
    constructor(props: any) {
        super(props)
        this.state = {
            error: false,
        }
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            const user = await UserApi.getInfoCurrentUser()
            this.setState({
                user: user,
            })
        } catch (e) {
            console.error(e)
            this.setState({ error: true })
        }
    }

    render() {
        if (this.state.error) {
            return <p>Erreur lors du chargement des informations utilisateur</p>
            // TODO rajouter un bouton réessayer
            // TODO  : ajouter un bouton déconnexion
        }

        // TODO : placer ceci au-desuss du contrôle précédent
        if (!AuthToken.has()) {
            return (
                <Navigate
                    to={{
                        pathname: '/login',
                    }}
                />
            )
        }

        if (!this.state.user) {
            return <CircularProgress />
        }

        // TODO : remplacer cet opérateur ternaire par un if pour limiter l'indentation
        // TODO : vérifier l'intérêt du Box racine
        return (
            <>
                {this.state.user.needPasswordChange &&
                !this.state.user.isCnpf ? (
                    <NeedPasswordChange user={this.state.user} />
                ) : (
                    <Box>
                        <Box
                            sx={{
                                width: '100vw',
                                height: '100vh',
                                display: 'flex',
                            }}
                        >
                            <SideNav current_user={this.state.user} />
                            <Box sx={{ width: 1, height: 1, overflow: 'auto' }}>
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/accueil" element={<Home />} />
                                    <Route path="/apk" element={<Apk />} />
                                    {/* Route admin à filtrer */}
                                    {this.state.user?.isAdmin ? (
                                        <>
                                            <Route
                                                path="/structures"
                                                element={<Structures />}
                                            />
                                            <Route
                                                path="/utilisateurs"
                                                element={<Users />}
                                            />
                                            <Route
                                                path="/utilisateurs/create"
                                                element={<EditionUser />}
                                            />
                                            <Route
                                                path="/utilisateurs/edit/:id"
                                                element={
                                                    <EditionUserMiddleWare />
                                                }
                                            />
                                            <Route
                                                path="/georestrictions"
                                                element={<Georestrictions />}
                                            />
                                            <Route
                                                path="/georestrictions/create"
                                                element={
                                                    <EditionGeorestriction />
                                                }
                                            />
                                            <Route
                                                path="/georestrictions/edit/:id"
                                                element={
                                                    <EditionGeorestrictionMiddleWare />
                                                }
                                            />
                                            <Route
                                                path="/credits"
                                                element={<Credits />}
                                            />
                                            <Route
                                                path="/financeurs"
                                                element={<Funders />}
                                            />
                                            <Route
                                                path="/cgu"
                                                element={<CGUs />}
                                            />
                                            <Route
                                                path="/versions_algos"
                                                element={<AlgosChangelogs />}
                                            />
                                            <Route
                                                path="/algos_js"
                                                element={<AlgosJS />}
                                            />
                                            <Route
                                                path="/rasters"
                                                element={<Rasters />}
                                            />
                                            <Route
                                                path="/export"
                                                element={<Export />}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Route
                                                path="/export"
                                                element={<Export />}
                                            />
                                        </>
                                    )}
                                </Routes>
                            </Box>
                        </Box>
                    </Box>
                )}
            </>
        )
    }
}
