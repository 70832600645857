import { Button, CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { GeneralFunctionsApi } from '../../api/general_functions_api'

import { APK } from '../../interfaces/apk_interface'

// TODO : Supprimer le dossier parent

interface ApkProps {}

interface ApkState {
    apk_data?: APK
}

export class Apk extends React.Component<ApkProps, ApkState> {
    constructor(props: ApkProps) {
        super(props)
        this.state = {}
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            const apk_data = await GeneralFunctionsApi.getApkData()
            this.setState({ apk_data: apk_data })
        } catch (e) {
            console.error(e)
            alert('Echec du chargement des données')
        }
    }

    render() {
        return (
            <Box sx={{ p: 5 }}>
                <Box sx={{ borderBottom: 1 }}>
                    <h2>Téléchargement de l'application mobile</h2>
                </Box>
                {this.state.apk_data ? (
                    <Box>
                        <br />
                        <Typography textAlign="left">
                            Version minimale de l'APK requise :{' '}
                            {this.state.apk_data.minRequired}
                        </Typography>
                        <br />
                        <Typography textAlign="left">
                            Dernière version de l'APK disponible :{' '}
                            {this.state.apk_data.latest}
                        </Typography>
                        <br />
                        <Typography textAlign="left">
                            <a href={this.state.apk_data.apkURL}>
                                <Button variant="contained">
                                    Télécharger l'apk
                                </Button>
                            </a>
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: 0.4,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 300,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        )
    }
}
