import { Box, Button, Modal, Paper } from '@mui/material'

// TODO : typer fortement des arguments
// TODO : supprimer le dossier parent de ce fichier

const Areyousure = ({
    message,
    close,
    action,
    cantPerform = false,
    reload,
}: any) => {
    return (
        <Modal open={true}>
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backdropFilter: 'blur( 5px )',
                    WebkitBackdropFilter: 'blur( 5px )',
                }}
                display="flex"
                position="fixed"
            >
                <Box
                    component={Paper}
                    sx={{
                        p: 3,
                        border: 1,
                        borderColor: 'primary.light',
                        maxWidth: 0.2,
                    }}
                    id="info-popup"
                >
                    <Box>
                        <h2>En êtes vous sûr ?</h2>
                        <Box sx={{ py: 2 }}>
                            En cliquant sur continuer, vous vous apprettez à{' '}
                            {message}.
                        </Box>
                    </Box>
                    <Box display="flex">
                        {cantPerform ? (
                            <></>
                        ) : (
                            <Button
                                sx={{ marginRight: 2 }}
                                variant="contained"
                                id="confirm-button"
                                onClick={async () => {
                                    await action()
                                    await reload()
                                    close()
                                }}
                            >
                                Continuer
                            </Button>
                        )}
                        <Button
                            color="inherit"
                            variant="outlined"
                            onClick={async () => {
                                await reload()
                                close()
                            }}
                            id="close-modal"
                            type="button"
                        >
                            Annuler
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default Areyousure
