import { get_restricted_url, get_unrestricted_url } from "../global_variables/backend_url_api";
import { Project } from "../interfaces/project_interface";
import { ApiClient } from "./api_client";
import { AuthToken } from "./token_api";



export class ProjectApi{
    static async listUserProjects(): Promise<Project[]> {
        return await ApiClient.get('/projects', {restricted:false})
    }

    static async allProjects(): Promise<Project[]> {
        return await ApiClient.get('/projects/all')
    }

    static async projectData(
        id: number,
    ): Promise<Blob>{
        return new Promise<Blob>((resolve, reject) => {
            const request = new XMLHttpRequest()
            request.open(
                'GET',
                `${get_unrestricted_url()}/projects/${id}`
            )
            request.setRequestHeader('Authorization', AuthToken.get())
            request.responseType = 'blob'

            request.onload = () => {
                if (request.status === 200) {
                    resolve(request.response)
                } else {
                    reject(new Error("Echec du téléchargement du projet"))
                }
            }

            request.send()
        })
    }

    static async usersProjectsCsv(
    ): Promise<Blob>{
        return new Promise<Blob>((resolve, reject) => {
            const request = new XMLHttpRequest()
            request.open("GET", `${get_unrestricted_url()}/projects/export`)
            request.setRequestHeader('Authorization', AuthToken.get())
            request.responseType = 'blob'

            request.onload = () => {
                if (request.status === 200) {
                    resolve(request.response)
                } else {
                    reject(new Error("Echec du téléchargement des projets"))
                }
            }
            request.send()
        })
    }

    static async AllProjectsCsv():
        Promise<Blob>{
        return new Promise<Blob>((resolve, reject) => {
            const request = new XMLHttpRequest()
            request.open("GET", `${get_restricted_url()}/projects/export/all`)
            request.setRequestHeader('Authorization', AuthToken.get())
            request.responseType = 'blob'

            request.onload = () => {
                if (request.status === 200) {
                    resolve(request.response)
                } else {
                    reject(new Error("Echec du téléchargement des projets"))
                }
            }
            request.send()
        })
    }
}