import React from 'react'
import { Project } from '../../interfaces/project_interface'
import { Button, TableCell, TableRow } from '@mui/material'
import { ProjectApi } from '../../api/projects_api'

export interface ProjectProps {
    project: Project
}

export class ProjectRow extends React.Component<ProjectProps> {
    constructor(props: ProjectProps) {
        super(props)
        this.jsonDownload = this.jsonDownload.bind(this)
        this.csvDownload = this.csvDownload.bind(this)
    }

    jsonDownload() {
        console.log('JSON DOWNLOAD ' + this.props.project.id)
        console.log(this.props.project.data)
    }

    jsonHref() {
        return `data:text/json;charset=utf-8, ${encodeURIComponent(
            JSON.stringify(this.props.project)
        )}`
    }

    jsonFilename() {
        return `projet-${this.props.project.id}.json`
    }

    async csvDownload() {
        console.log('CSV DOWNLOAD id : ' + this.props.project.id)

        try {
            const download = await ProjectApi.projectData(this.props.project.id)
            const blobUrl = URL.createObjectURL(download)
            const link = document.createElement('a')
            link.href = blobUrl
            link.download = `project-${this.props.project.id}.tar.gz`
            link.click()
            URL.revokeObjectURL(blobUrl)
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        return (
            <TableRow key={this.props.project.id}>
                <TableCell>{this.props.project.id}</TableCell>
                <TableCell>{this.props.project.userId}</TableCell>
                <TableCell>
                    <Button onClick={this.csvDownload}>Format CSV</Button>
                </TableCell>
                <TableCell>
                    <Button
                        download={this.jsonFilename()}
                        href={this.jsonHref()}
                    >
                        Format Json
                    </Button>
                </TableCell>
            </TableRow>
        )
    }
}
