import {
    mdiAccountSearch,
    mdiAlphaCCircle,
    mdiCellphoneArrowDownVariant,
    mdiCodeJson,
    mdiExport,
    mdiHandshake,
    mdiHome,
    mdiLeaf,
    mdiMap,
    mdiScaleBalance,
    mdiSelectionMarker,
    mdiUpdate,
} from '@mdi/js'
import { Button, List, Paper } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import logo_bioclimsol from '../../../assets/images/logo_bioclimsol.png'
import NavLink from './NavLink/NavLink'

export interface SideNavProps {
    current_user: any // TODO : typer fortement
}

interface SideNavState {
    uri: string
    link_list: any[]
}

// TODO : refactoriser la gestion de l'affichage du menu
export class SideNav extends React.Component<SideNavProps, SideNavState> {
    constructor(props: SideNavProps) {
        super(props)
        this.state = {
            uri: '',
            // TODO : dégager du state et placer dans le render
            link_list: [
                { name: 'Structures', uri: '/structures', icon: mdiLeaf },
                {
                    name: 'Georestrictions',
                    uri: '/georestrictions',
                    icon: mdiSelectionMarker,
                },
                {
                    name: 'Utilisateurs',
                    uri: '/utilisateurs',
                    icon: mdiAccountSearch,
                },
                { name: 'Algos JS', uri: '/algos_js', icon: mdiCodeJson },
                { name: 'Cartes Rasters', uri: '/rasters', icon: mdiMap },
                { name: 'Credits', uri: '/credits', icon: mdiAlphaCCircle },
                { name: 'Financeurs', uri: '/financeurs', icon: mdiHandshake },
                {
                    name: 'Versions Algos',
                    uri: '/versions_algos',
                    icon: mdiUpdate,
                },
                { name: 'CGU', uri: '/cgu', icon: mdiScaleBalance },
            ],
        }
        this.changeUri = this.changeUri.bind(this)
    }

    componentDidMount(): void {
        this.setState({ uri: window.location.pathname })
    }

    changeUri(uri: string) {
        this.setState({ uri: uri })
    }

    disconnect() {
        sessionStorage.removeItem('userToken')
        window.location.pathname = '/'
    }

    render() {
        return (
            <Box
                sx={{
                    p: 2,
                    paddingTop: 1,
                    width: 0.15,
                }}
                component={Paper}
            >
                <Box>
                    <nav>
                        <List>
                            <img
                                src={logo_bioclimsol}
                                alt="Logo de BioClimSol"
                                style={{ width: '200px', paddingBottom: 5 }}
                            />
                            <Box
                                sx={{
                                    p: 1,
                                    backgroundColor: '#efffef',
                                    borderRadius: 1,
                                    border: 1,
                                    borderColor: this.props.current_user.isAdmin
                                        ? 'transparent'
                                        : '#dddddd',
                                }}
                            >
                                <p>
                                    {this.props.current_user.lastname.toUpperCase() +
                                        ' ' +
                                        this.props.current_user.firstname}
                                </p>
                            </Box>
                            <NavLink
                                changeUri={this.changeUri}
                                name="Accueil"
                                mdiIcon={mdiHome}
                                self_uri="/accueil"
                                current_uri={this.state.uri}
                            />
                            <h3>Export</h3>
                            <NavLink
                                changeUri={this.changeUri}
                                name="Export"
                                mdiIcon={mdiExport}
                                self_uri="/export"
                                current_uri={this.state.uri}
                            />
                            <h3>Administration</h3>
                            {this.props.current_user.isAdmin ? (
                                this.state.link_list.map((element) => (
                                    <NavLink
                                        changeUri={this.changeUri}
                                        name={element.name}
                                        mdiIcon={element.icon}
                                        self_uri={element.uri}
                                        current_uri={this.state.uri}
                                        key={element.uri}
                                    />
                                ))
                            ) : (
                                <></>
                            )}
                            <NavLink
                                changeUri={this.changeUri}
                                name="APK"
                                mdiIcon={mdiCellphoneArrowDownVariant}
                                self_uri="/apk"
                                current_uri={this.state.uri}
                            />
                            <Button
                                fullWidth
                                color="error"
                                variant="outlined"
                                onClick={this.disconnect}
                            >
                                Déconnexion
                            </Button>
                        </List>
                    </nav>
                </Box>
            </Box>
        )
    }
}
