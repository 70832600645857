import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
} from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { GeneralFunctionsApi } from '../../api/general_functions_api'
import { StructureApi } from '../../api/structures_api'
import { UserApi } from '../../api/users_api'
import { SearchInput } from '../../components/Inputs/SearchInput/SearchInput'
import { SelectInput } from '../../components/Inputs/SelectInput/SelectInput'
import { UserTable } from './user_table'
import { User } from '../../interfaces/user_interface'

export interface UsersProps {}

interface Userstate {
    users: User[] // TODO : corriger le typage
    structures: any[] // TODO : corriger le typage

    // TODO : remplacer sub par filter
    subFirstname: string
    subLastname: string
    subEmail: string
    subPhoneNumber: string
    subMaillingAddress: string
    structureId: number
    expireIn: number
    isCnpf: any // TODO : corriger le typage
    is_active: any // TODO : corriger le typage
    isAdmin: any // TODO : corriger le typage
    grouped_open: boolean
    is_loaded: boolean
    lastUsed: number
}

export class Users extends React.Component<UsersProps, Userstate> {
    constructor(props: UsersProps) {
        super(props)
        this.state = {
            users: [],
            structures: [],
            subFirstname: '',
            subLastname: '',
            subEmail: '',
            subPhoneNumber: '',
            subMaillingAddress: '',
            structureId: NaN,
            expireIn: NaN,
            isCnpf: null,
            is_active: null,
            isAdmin: null,
            grouped_open: false,
            is_loaded: false,
            lastUsed: NaN
        }
        this.getStructures = this.getStructures.bind(this)
    }

    componentDidMount(): void {
        this.load()
    }
    async load() {
        try {
            const users = await UserApi.listUsers()
            const structures = await this.getStructures()

            this.setState({
                users: GeneralFunctionsApi.SortByID(users),
                structures: structures,
            })
            this.setState({ is_loaded: true })
        } catch (e) {
            console.error(e)
            alert('Echec du chargement de la liste des utilisateurs')
        }
    }

    async getStructures() {
        const structureList = await StructureApi.listStructures()
        return structureList.map((structure: any) => {
            return { name: structure.name, value: structure.id }
        })
    }


    async filter() {
        const userList = await UserApi.listUsers()
        const filtered = userList.filter(
            (user: any) =>
                user.firstname
                    .toLowerCase()
                    .includes(this.state.subFirstname.toLowerCase()) &&
                user.lastname
                    .toLowerCase()
                    .includes(this.state.subLastname.toLowerCase()) &&
                user.email
                    .toLowerCase()
                    .includes(this.state.subEmail.toLowerCase()) &&
                ((this.state.is_active && !user.isActive) || !this.state.is_active) && //user.is_activ remplacé par user.isActiv
                ((this.state.isCnpf && user.isCnpf) || !this.state.isCnpf) &&
                ((this.state.isAdmin && user.isAdmin) || !this.state.isAdmin) &&
                ((this.state.structureId &&
                    user.structureId === Number(this.state.structureId)) ||
                    !this.state.structureId) &&
                ((this.state.expireIn &&
                    (new Date(user.expireOn*1000).getTime() >= new Date().getTime()) // ExpireOn après la date actuelle
                    &&
                    Math.floor(
                        (new Date(user.expireOn*1000).getTime() - new Date().getTime())/(24*1000*60*60)) // Divisé par une journée en milisecondes
                     <= this.state.expireIn) ||
                    !this.state.expireIn) &&
                (((this.state.lastUsed && user.devices[0] !== undefined &&
                    Math.floor(
                        (new Date().getTime() - new Date((user.devices[0]).lastUsed*1000).getTime())/(24*1000*60*60))
                         >= this.state.lastUsed) || user.devices[0] === undefined) ||
                    !this.state.lastUsed)
                    )
        this.setState({ users: GeneralFunctionsApi.SortByID(filtered) })
    }
    render() {
        return (
            <Box sx={{ p: 5 }}>
                <Box sx={{ borderBottom: 1, mb: 2 }}>
                    <h2>Gestion des utilisateurs</h2>
                </Box>
                <Box>
                    <Link to="create">
                        <Button variant="outlined">
                            <span>Ajouter un utilisateur</span>
                        </Button>
                    </Link>
                </Box>
                <Box display="flex" sx={{ py: 4 }}>
                    {[
                        {
                            label: 'Prénom',
                            type: 'text',
                            changeValue: (firstname: string) => {
                                this.setState({ subFirstname: firstname })
                                this.filter()
                            },
                        },
                        {
                            label: 'Nom',
                            type: 'text',
                            changeValue: (lastname: string) => {
                                this.setState({ subLastname: lastname })
                                this.filter()
                            },
                        },
                        {
                            label: 'Email',
                            type: 'text',
                            changeValue: (email: string) => {
                                this.setState({ subEmail: email })
                                this.filter()
                            },
                        },
                        {
                            label: 'Non connecté depuis X jours',
                            type: 'number',
                                changeValue: (number: number) => {
                                    this.setState({ lastUsed: number })
                                    this.filter()
                            },
                        },
                        {
                            label: 'Licences expirant dans X jours',
                            type: 'number',
                            changeValue: (number: number) => {
                                this.setState({ expireIn: number })
                                this.filter()
                            },
                        },
                    ].map((element, index) => (
                        <SearchInput
                            label={element.label}
                            type={element.type}
                            changeValue={element.changeValue}
                            key={index}
                        />
                    ))}
                    <SelectInput
                        options={this.state.structures}
                        label="Structures"
                        filter={(structure_id: number) => {
                            this.setState({ structureId: structure_id })
                            this.filter()
                        }}
                        structureId={this.state.structureId}
                    />
                    <FormGroup sx={{ color: 'text.primary', px: 4 }}>
                        {[
                            {
                                name: 'Administrateurs',
                                action: (_event: any) => {
                                    this.setState({
                                        isAdmin: !this.state.isAdmin,
                                    })
                                    this.filter()
                                },
                            },
                            {
                                name: 'CNPF',
                                action: (_event: any) => {
                                    this.setState({
                                        isCnpf: !this.state.isCnpf,
                                    })
                                    this.filter()
                                },
                            },
                            {
                                name: 'Inactif',
                                action: (_event: any) => {
                                    this.setState({
                                        is_active: !this.state.is_active,
                                    })
                                    this.filter()
                                },
                            },
                        ].map((object, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        onChange={object.action}
                                        sx={{
                                            '&.Mui-checked': {
                                                color: 'secondary.main',
                                            },
                                        }}
                                    />
                                }
                                label={object.name}
                            />
                        ))}
                    </FormGroup>
                </Box>{' '}
                {this.state.is_loaded ? (
                    <UserTable
                        users={this.state.users}
                        reload={async () => {
                            await this.load()
                        }}
                    />
                ) : (
                    <Box
                        sx={{
                            width: 1,
                            height: '500px',
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        )
    }
}
