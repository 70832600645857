import { mdiFilePlus } from '@mdi/js'
import { Icon } from '@mdi/react'
import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    Tooltip,
} from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { AlgosJavascriptApi } from '../../api/algos_javascript_api'
import { AlgosJavascript } from '../../interfaces/algosJavascript_interface'
import { AlgosJSTable } from './algos_js_table'

interface AlgosJSProps {}

interface AlgosJSState {
    algos_js?: AlgosJavascript[] // TODO : renommer avec un nom plus explicite : list
    new_algo: AlgosJavascript // TODO : simplifier avec juste le message et le contenu du nouvel algo
    current_file?: File // TODO : quel est l'intéret de cette propriété ?
}

export class AlgosJS extends React.Component<AlgosJSProps, AlgosJSState> {
    constructor(props: AlgosJSProps) {
        super(props)
        this.state = {
            new_algo: {
                inUse: false,
                betaUse: false,
                message: '',
                content: '',
            },
        }
        this.load = this.load.bind(this)
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            const algos_js = await AlgosJavascriptApi.listAlgosJS()
            this.setState({ algos_js: algos_js })
        } catch (e) {
            console.error(e)
            alert("Echec du chargement des données d'algorithmes JS")
        }
    }

    // TODO : clarifier ce que fait cette fonction
    async handleNewFile(file: File) {
        const new_algo = this.state.new_algo
        const data = await file.text()
        new_algo.content = data
        this.setState({ new_algo: new_algo, current_file: file })
    }

    render() {
        return (
            <Box sx={{ p: 5 }}>
                <Box sx={{ borderBottom: 1 }}>
                    <h2>Versions du fichier Javascript des algorithmes</h2>
                </Box>
                <Box sx={{ pt: 5, pb: 2 }}>
                    <p>Mise en ligne d'une nouvelle version</p>
                    <Grid container gap={6} sx={{ mt: 2 }}>
                        <Grid
                            item
                            sx={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            <Box display="flex" sx={{ alignItems: 'center' }}>
                                <Tooltip
                                    title="Ajouter un fichier JS"
                                    placement="bottom"
                                >
                                    <Button
                                        variant="contained"
                                        component="label"
                                    >
                                        <Icon
                                            path={mdiFilePlus}
                                            className="app_svg"
                                        />
                                        <input
                                            type="file"
                                            accept=".js"
                                            hidden
                                            onChange={(event) => {
                                                // TODO : fusionner ce corps de fonction avec handleNewFile
                                                if (event.target.files)
                                                    this.handleNewFile(
                                                        event.target.files[0]
                                                    )
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                                <Box sx={{ pl: 2 }}>
                                    <p>
                                        {this.state.current_file
                                            ? this.state.current_file.name
                                            : 'Aucun fichier sélectionné pour le moment'}
                                    </p>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            sx={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            <TextField
                                fullWidth
                                required
                                variant="standard"
                                label="Message associé au fichier"
                                onChange={(event) => {
                                    const new_algo = this.state.new_algo
                                    new_algo.message = event.target.value
                                    this.setState({ new_algo: new_algo })
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            sx={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            <Button
                                variant="outlined"
                                onClick={async () => {
                                    // TODO : Créer une méthode de classe spécialisée
                                    await AlgosJavascriptApi.createAlgosJS(
                                        this.state.new_algo
                                    )
                                        .then(() => {
                                            this.load()
                                        })
                                        .catch((error) => {
                                            throw new Error(error.message)
                                        })
                                }}
                            >
                                Mettre en ligne
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {this.state.algos_js ? (
                    <AlgosJSTable
                        algos_js={this.state.algos_js}
                        load={this.load}
                    />
                ) : (
                    <Box
                        sx={{
                            height: 'calc(100vh - 200px)',
                            width: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        )
    }
}
